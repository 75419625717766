<template>
    <div class="productContainer">
        <!-- 面包屑 -->
        <div class="bread">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread"  :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }"><a>产品中心</a></el-breadcrumb-item>
                <el-breadcrumb-item>热门产品</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main" >
           <h4 class="title">热门产品</h4>
           <hr>
           <div class="content" v-loading="loading">
              <div class="listItem" v-for="(item,index) in list" :key="index" @click="clickList(item)">
                <div class="itemTitle">
                    <div class="leftTitle">{{item.leftTitle}}</div>
                    <div class="rightTitle">{{item.rightTitle}}</div>
                </div>
                <div class="itemContent">{{item.text}}</div>
              </div>
           </div>
        </div>
       
    </div>
</template>
<script>
import { hotProducts } from '@/services/product.js'
export default {
  name: "hotProducts",
  data() {
    return {
        loading:false,
        list:[
            // {
            //     leftTitle:'32位USB+USB PD单片机',
            //     rightTitle:'CH32X035',
            //     text:'PDUSB接口，支持Type-C数据传输与PD功率传输；多组运放、比较器；适于Type-C应用/电机方案开发。',
            //     link:'https://www.wch.cn/products/CH32X035.html',
            // },
            // {
            //     leftTitle:'RISC-V内核BLE5.4无线MCU',
            //     rightTitle:'CH592',
            //     text:'提供20*4段式LCD驱动、全速USB主机/设备接口，定位于更低功耗、更低成本LCD显示的蓝牙应用。',
            //     link:'https://www.wch.cn/products/CH592.html',
            // },
            // {
            //     leftTitle:'增强型低功耗RISC-V单片机',
            //     rightTitle:'CH32V203',
            //     text:'144MHz主频，中断提速、功耗优化；双USB接口、四串口、双运放等丰富资源，面向通用和电机场景。',
            //     link:'https://www.wch.cn/products/CH32V203.html',
            // },
            // {
            //     leftTitle:'互联型RISC-V单片机',
            //     rightTitle:'CH32V307',
            //     text:'480Mbps高速USB(内置PHY)，千兆以太网MAC(内置10MPHY)，双ADC、双DAC、8串口、4运放。',
            //     link:'https://www.wch.cn/products/CH32V307.html',
            // },
            // {
            //     leftTitle:'高集成度、低功耗USB网卡芯片',
            //     rightTitle:'CH397',
            //     text:'自研高速USB控制器及收发器PHY、100M/10M以太网MAC+PHY。低成本，轻松构建USB网卡方案。',
            //     link:'https://www.wch.cn/products/CH397.html',
            // },
            // {
            //     leftTitle:'USB HUB控制器芯片',
            //     rightTitle:'CH334/5',
            //     text:'工业级设计，高性能MTT模式，较STT带宽提升4倍，自研专用USB PHY，功耗低，多种封装，外围精简。',
            //     link:'https://www.wch.cn/products/CH334.html',
            // },
            // {
            //     leftTitle:'高速USB转接芯片',
            //     rightTitle:'CH347',
            //     text:'480Mbps高速USB2.0转JTAG/SWD/SPI/I2C/双串口芯片，JTAG支持30Mbps，SPI支持60MHz。',
            //     link:'https://www.wch.cn/products/CH347.html',
            // },
            // {
            //     leftTitle:'USB转8串口芯片',
            //     rightTitle:'CH348',
            //     text:'自研高速USB收发器和控制器，最高6Mbps波特率，支持I/O独立供电、MODEM信号、至多48路GPIO。',
            //     link:'https://www.wch.cn/products/CH348.html',
            // },
            // {
            //     leftTitle:'网络串口透传芯片',
            //     rightTitle:'CH9120',
            //     text:'集成以太网MAC和PHY，内置TCP/IP协议栈。双向透明传输，支持200米通讯距离，小封装、低功耗。',
            //     link:'https://www.wch.cn/products/CH9120.html',
            // },
            // {
            //     leftTitle:'USB PD快充协议芯片',
            //     rightTitle:'CH233',
            //     text:'Type-C单口快充，SOT23-6封装，支持3.3V~21V的PD3.0/2.0、PPS快充协议，支持FB灌电流调节。',
            //     link:'https://www.wch.cn/products/CH233.html',
            // },
        ],
        
        url:{
          listUrl:'/api/official/website/articles/getHotArticle'
        }
    };
  },
  computed: {
  },
  created() {
  },
  destroyed() {
  },
  watch: {
  },
  mounted() {
  },
  activated(){
    this.getList()
  },
  methods:{
    //点击列表项
    clickList(item){
        window.open(item.link)
        
    },
    //获取页面内容
    getList(){
      // getAction(this.url.listUrl,).then(res => {
      //     this.loading = true;
      //     if(res.data.code===0 && res.data.data){
      //       this.list = res.data.data
      //       this.list.forEach((item,index)=>{
      //         if(item.leftTitle.indexOf('CH')!=-1){
      //           item.leftTitle = item.leftTitle.slice(0,item.leftTitle.indexOf('CH')-1)
      //         }
      //         item.link = 'https://www.wch.cn/products/'+item.rightTitle+'.html'
      //       })
      //       document.title = '热门产品 - 南京沁恒微电子股份有限公司'
      //   }else{
      //       this.$message.error(res.data.message);
      //   }
      //   this.loading = false;
      // });
      hotProducts().then(res => {
        this.loading = true;
        if(res.data.code===0 && res.data.data){
          this.list = res.data.data
          this.list.forEach((item,index)=>{
            if(item.leftTitle.indexOf('CH')!=-1){
              item.leftTitle = item.leftTitle.slice(0,item.leftTitle.indexOf('CH')-1)
            }
            item.link = 'https://www.wch.cn/products/'+item.rightTitle+'.html'
          })
          document.title = '热门产品 - 南京沁恒微电子股份有限公司'
        }else{
            this.$message.error(res.data.message);
        }
        this.loading = false;
      });
    },
    
  }
};
</script>
<style scoped>
 .productContainer{
    width: 100%;
    text-align: left;
 }
 .bread{
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .main {
    background: #fff;
    margin-top: 20px;
    box-shadow: 6px 6px 12px #0000001a;
    border-radius: 4px;
 }
 .main h4{
    display: block;
    font-size: 20px;
    color: #1ea9fb;
    line-height: 18px;
    font-weight: 700;
    word-break: break-all;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    padding: 40px 0px;
    padding-left: 30px;
    margin: 0px;
 }
 .main hr{
    border-top: 1px solid #cbd0d4;
    margin: 0px;
 }
 .main .content{
    padding: 10px;
    margin-top: 10px;
 }
 .listItem{
    padding: 15px 10px;
    box-shadow: -2px 1px 8px 1px rgba(36,36,36,.2);
    border-top: 3px solid #1ea9fb;
    margin-bottom: 20px;
    cursor: pointer;
 }
 .itemTitle{
    display: flex;
    justify-content: space-between;
    color: #1482DC;
    font-size: 16px;
    font-weight: 700;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
 }
 .itemContent{
    display: -webkit-box;
    font-size: 14px;
    color: #333;
    margin-top: 10px;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    height: 40px;
    line-height: 20px;
    width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
 }

@media screen and (max-width: 370px) {
    .itemTitle {
        display: block;
    }
}
</style>